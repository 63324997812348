import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "email"
    }}>{`Email`}</h1>
    <p>{`Dragnet allows you to embed tokens into emails.  The application features an email template designer that allows you to create custom email templates.  Alternatively, you can select from any of our templated emails.  See the help section on the email template editor for detailed instructions on how to use the editor.`}</p>
    <p>{`To send an Email Token, first create the token by selecting `}<inlineCode parentName="p">{`Email`}</inlineCode>{` from the top dropdown in the `}<inlineCode parentName="p">{`New Token`}</inlineCode>{` interface and complete the rest of the form as necessary.`}</p>
    <p>{`Navigate to the Email Token you created and click to `}<inlineCode parentName="p">{`View Details`}</inlineCode>{`.  Then click to `}<inlineCode parentName="p">{`Send Email`}</inlineCode>{`.  This will prompt you to enter the recipient's email address and complete any `}<inlineCode parentName="p">{`Substitution Data`}</inlineCode>{` that the template may feature.`}</p>
    <p>{`Click `}<inlineCode parentName="p">{`Send Email`}</inlineCode>{` and the email bearing the token will be sent ot the recipient.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      